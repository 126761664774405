import { css } from '@emotion/react';
import { Breakpoint, Color, Font, FontHeadingSize, FontTextSize } from '@pafcloud/style';

export const PrimaryHeading = css({
  color: Color.HeadingText,

  margin: 0,
  marginRight: 'auto',
  fontFamily: Font.HeadingAlt,
  fontSize: FontTextSize.Huge,
  fontWeight: 'normal',

  [Breakpoint.TabletOrLarger]: {
    fontSize: FontHeadingSize.Small,
  },
});
